import React from 'react'
import { cleanUpPathname } from './utils/index'
import Analytics from './utils/analytics'

export default class OptInForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      form_interest: props.opt_in_form.form_interest,
      mailchimp_group_id: props.opt_in_form.mailchimp_group_id,
      mailchimp_group_list_id: props.opt_in_form.mailchimp_group_list_id,
      mailchimp_tag_id: props.opt_in_form.mailchimp_tag_id,
      submitButtonDisabled: false,
      submitted: false,
      asyncRequests: [],
      form: ''
    };
  }

  showContent = () => {
    if(this.state.submitted) {
      let hiddenContent = document.getElementsByClassName('d-none')
      for (let i = 0; i < hiddenContent.length; i++) {
        hiddenContent[i].classList.remove('d-none')
      }
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let {
      email,
      form_interest,
      mailchimp_group_id,
      mailchimp_group_list_id,
      mailchimp_tag_id,
    } = this.state
    this.setState({form: e.target});

    this.setState({submitButtonDisabled: true});
    Analytics.identifyUser(email, {
      id: email,
      email: email,
      interestedProduct: form_interest
    }, () => {
      console.log(`opt in: ${this.state.asyncRequests}`)
      this._checkAllAsyncRequestsCompleted('identifyUser')
    })

    window.analytics.track('Opted-in', {
      form_name: cleanUpPathname(),
      opt_in_page: cleanUpPathname()
    }, () => {
      console.log(`opt in: ${this.state.asyncRequests}`)
      this._checkAllAsyncRequestsCompleted('trackOptedIn')
    })

    if(process.env.NODE_ENV === "production") {
      Analytics.submitToMailchimp(email, mailchimp_group_id, mailchimp_group_list_id, mailchimp_tag_id)
      .then(() => {
        this._checkAllAsyncRequestsCompleted('mailchimpSubmit')
      })
    }
  }

  _checkAllAsyncRequestsCompleted(name) {
    let { asyncRequests } = this.state
    asyncRequests.push(name)
    let numAsyncReq;
    if (process.env.NODE_ENV === "production") {
      numAsyncReq = 3
    } else {
      numAsyncReq = 2
    }
    if (asyncRequests.length === numAsyncReq) {
      asyncRequests = []
      this.setState({submitted: true})
      this.showContent()
    }
  }

  _cleanUpPathname(){
    var path = window.location.pathname.replace(/\.html/, "").replace(/\//, "")
    if( path === "" ){
      return "home"
    }else{
      return path
    }
  }

  render () {
    const { opt_in_form, padding, classes }   = this.props

    if (this.state.submitted) {
      return (
        <div className={`card ${classes}`}>
          <div className={`card-body ${padding}`}>
            <p className="m-3 text-center text-dark"><span role="img" aria-labelledby="yay">🎉</span> Awesome! You will receive an email soon.</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`card ${classes}`}>
          <div className={`card-body ${padding}`}>
            <h5 className="mb-4" dangerouslySetInnerHTML={{ __html: opt_in_form.description }}></h5>
            <form id="lead-form" name={opt_in_form.form_name} action={opt_in_form.form_action} onSubmit={this.handleSubmit}>
              <div className="form-group">
                <input className="form-control" type="email" name="email" id="email" placeholder="Enter Your Email" value={this.state.email} onChange={this.handleChange} required />
                <input type="hidden" name="interest" value={opt_in_form.form_interest} />
                <button type="submit" className="btn btn-orange submit mt-3" disabled={this.state.submitButtonDisabled}>{opt_in_form.form_submit}</button>
              </div>
            </form>
          </div>
        </div>
      )
    }
  }
}
