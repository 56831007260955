import React from 'react'
import { graphql } from 'gatsby'

import ResourceOptInForm from '../components/resourceoptinform'
import Layout from '../components/layouts/layout'
import HeadMeta from '../components/layouts/headmeta'
import ProgressBar from '../components/layouts/progressbar'
import { changeProgressBarOnScroll } from '../components/utils/index'

export default class LandingPage extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', function() {
      changeProgressBarOnScroll()
    });
    window.analytics.page('Landing', this.props.data.markdownRemark.frontmatter.title)
  }

  render() {
    const { data } = this.props
    const {
      metadata,
      hero_image,
      title,
      description,
      opt_in_form,
     } = data.markdownRemark.frontmatter

    return (
      <Layout>
        <div className="landing-page" id="eat-well">
          <ProgressBar />
          <HeadMeta metadata={metadata} />
          <section className="hero hero-landing bg-gray" style={{ backgroundImage: `url(${hero_image})` }}>
            <div className="background-tint d-flex align-items-start">
              <div className="container">
                <div className="row d-flex">
                  <div className="col-sm-8">
                    <h1 className="text-dark" dangerouslySetInnerHTML={{__html: title}}></h1>
                    <p className="lead text-dark" dangerouslySetInnerHTML={{ __html: description }}></p>
                    <br/>
                  </div>
                  <div className="col-sm-4">
                    <ResourceOptInForm opt_in_form={opt_in_form} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export const landingPageQuery = graphql`
  query LandingPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        metadata {
          title
          description
          url
        }
        hero_image
        title
        description
        opt_in_form {
          header
          description
          form_name
          form_action
          form_interest
          form_submit
          mailchimp_group_id
          mailchimp_group_list_id
          mailchimp_tag_id
        }
      }
    }
  }
`
